import { type SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId1 = `arrow-down-duo-light-icon-gradient-${id}`;
  const gradientId2 = `arrow-down-duo-light-icon-gradient-${id}`;
  const gradientId3 = `arrow-down-duo-light-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3C19.5523 3 20 3.44772 20 4V9H25C25.5523 9 26 9.44772 26 10C26 10.5523 25.5523 11 25 11H19C18.4477 11 18 10.5523 18 10V4C18 3.44772 18.4477 3 19 3Z"
        fill="#0B1423"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 30H8C6.34315 30 5 28.6569 5 27V5C5 3.34315 6.34314 2 8 2H18.5858C19.3814 2 20.1445 2.31607 20.7071 2.87868L26.1213 8.29289C26.6839 8.8555 27 9.61857 27 10.4142V27C27 28.6569 25.6569 30 24 30ZM24 28H8C7.44772 28 7 27.5523 7 27V5C7 4.44772 7.44772 4 8 4H18.5858C18.851 4 19.1054 4.10536 19.2929 4.29289L24.7071 9.70711C24.8946 9.89464 25 10.149 25 10.4142V27C25 27.5523 24.5523 28 24 28Z"
        fill="#0B1423"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15C9 14.4477 9.44772 14 10 14H21C21.5523 14 22 14.4477 22 15C22 15.5523 21.5523 16 21 16H10C9.44772 16 9 15.5523 9 15Z"
        fill={`url(#${gradientId1})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 19C9 18.4477 9.44772 18 10 18H15C15.5523 18 16 18.4477 16 19C16 19.5523 15.5523 20 15 20H10C9.44772 20 9 19.5523 9 19Z"
        fill={`url(#${gradientId2})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 23C9 22.4477 9.44772 22 10 22H15C15.5523 22 16 22.4477 16 23C16 23.5523 15.5523 24 15 24H10C9.44772 24 9 23.5523 9 23Z"
        fill={`url(#${gradientId3})`}
      />
      <defs>
        <linearGradient
          id="paint0_linear_77_1044"
          x1="9.14773"
          y1="15.2333"
          x2="12.6372"
          y2="8.48811"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.322917" stopColor="#9A455A" />
          <stop offset="1" stopColor="#9A455A" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1="9.07955"
          y1="19.2333"
          x2="13.3513"
          y2="14.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.322917" stopColor="#9A455A" />
          <stop offset="1" stopColor="#9A455A" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id={gradientId3}
          x1="9.07955"
          y1="23.2333"
          x2="13.3513"
          y2="18.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.322917" stopColor="#9A455A" />
          <stop offset="1" stopColor="#9A455A" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Document = memo(SvgComponent);
